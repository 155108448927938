import * as React from "react";
import { Grid, Typography, useMediaQuery, SvgIcon, Paper, Divider, CircularProgress, Button, Dialog, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import Menu from "../../components/ui/Menu";
import useLabels from "../../labels/useLabels";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CustomerService from "../../services/CustomerService";
import HttpService from "../../services/HttpService";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LoadingButton from "../../components/ui/LoadingButton";
import MainBackground from "../../components/ui/MainBackground";
import { useMutation } from "@tanstack/react-query";
import OnboardingEmployerAttributeService from "../../services/config/OnboardingEmployerAttributeService";
import CardService from "../../services/CardService";
import store from "../../store/store";



export default function Agreements({employerId}) {
  const {getLabel} = useLabels();
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const customerService = CustomerService();
  const [htmlContent, setHtmlContent] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [keyClicked, setKeyClicked] = React.useState([]);
  const cardService = CardService();
  const cardType = cardService?.cardDetail?.shortLabel || "";
  const language = store.getState()?.language?.profile;

  const onboardingEmployerAttributeService = OnboardingEmployerAttributeService(customerService?.customerInfo?.companyId, "DIRECT_DEPOSIT_ACCEPT,HIDE_FEE_SCHEDULE_ACCEPT,HIDE_CARDHOLDER_ACCEPT,HIDE_PRODUCT_ACCEPT").attribute;

  const [documents, setDocuments] = React.useState([]);
  const seePDFsRefs = React.useRef({});


  React.useEffect (() => {
    setDocuments([
      ...(onboardingEmployerAttributeService?.HIDE_FEE_SCHEDULE_ACCEPT ?  []: [{ name: "Fee Schedule", key: "feeSchedule_" + language, type: "HTML", url : null }]),
      { name: "USA Patriot Act", key: "patriotAct_" + language, type: "HTML", url : null },
      { name: "ESING Disclousure", key: "eSignDisclosure_" + language, type: "PDF" , url : null},
      ...(onboardingEmployerAttributeService?.HIDE_CARDHOLDER_ACCEPT ?  []: [{name: "Cardholder agreement",key: "cardHolderAgreement_" + language,type: "PDF",url : null}]),
      ...(onboardingEmployerAttributeService?.HIDE_CARDHOLDER_ACCEPT ?  []: [{name: "Digital product agreement",key: "digitalAccountAgreement_" + language,type: "PDF",url : null}]),
      ...(onboardingEmployerAttributeService?.DIRECT_DEPOSIT_ACCEPT ? [{name: "Change my Direct Deposit",key: "changeMyDirectDeposit_" + language,type: "PDF",url : null},]: []),
    ])
  }, [onboardingEmployerAttributeService?.DIRECT_DEPOSIT_ACCEPT,
      onboardingEmployerAttributeService?.HIDE_FEE_SCHEDULE_ACCEPT,
      onboardingEmployerAttributeService?.HIDE_CARDHOLDER_ACCEPT,

    language]);

  const setURL = (key, url) => {
    const updatedDocuments = documents.map((doc) => {
      if (doc.key === key) {
        return { ...doc, url: url };
      }
      return doc;
    });
    setDocuments(updatedDocuments);
  };

  const donwloadPDF = useMutation(
    (key) => {
      return HttpService.getAxiosClient().get(
        window.API_URL + `/document/` + key + `/getPDF?cardType=${ (cardType==="PAYCARD" || cardType==="CORPORATE_EXPENSE")?"PAYCARD":""}`,
        {responseType: "json",},
        { avoidBearer: true }
      ).then(response => ({response, key}));
    },
    {
      onSuccess: ({response, key}) => {
        const binaryString = window.atob(response.data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        setURL(key, url);
        setKeyClicked(prevKeys => prevKeys.filter(currentKey => currentKey !== key));
      },
      onError: ({response, key}) => {
        setKeyClicked(prevKeys => prevKeys.filter(currentKey => currentKey !== key));
      },
    }
  );

  const downloadHtml = useMutation(
    (key) => {
      return HttpService.getAxiosClient().get(
        window.API_URL + `/document/` + key + `/getHTML?cardType=${(cardType==="PAYCARD" || cardType==="CORPORATE_EXPENSE")?"PAYCARD":""}`,
        {},
        { avoidBearer: true }
      ).then(response => ({response, key}));;
    },
    {
      onSuccess: ({response, key}) => { 
        setHtmlContent(response.data);
        setOpenDialog(true);
        setKeyClicked(prevKeys => prevKeys.filter(currentKey => currentKey !== key));
      },
      onError: ({response, key}) => {
        setKeyClicked(prevKeys => prevKeys.filter(currentKey => currentKey !== key));
      },
    }
  );

  const handleDialogClose = (event, reason) => {
    if (reason !== "backdropClick") setOpenDialog(false);
  };

  const dialogUI = (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Grid>
          <Grid item width="100%">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleDialogClose}
            >
              {getLabel("cardActivation.btnClose", "Close")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  const handleDownloadAgreementClick = (event, key, type) => {
    setKeyClicked(prevKeys => [...prevKeys, key]);

    if (type === "PDF") 
      donwloadPDF.mutate(key);
    if (type === "HTML") 
      downloadHtml.mutate(key);

    event.preventDefault();
  };

  if (onboardingEmployerAttributeService===null ||
    onboardingEmployerAttributeService===undefined ||
    onboardingEmployerAttributeService?.isLoading)     return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <CircularProgress color="secondary" />
    </Box>
  );

  if (
    customerService.isLoading
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <div style={{ display: true ? "block" : "none" }}>
      {dialogUI}
      <MainBackground
        component="form"
        noValidate
        autoComplete="off"
        isMobile={isMobile}
      >
        <Grid container style={{width:isMobile?"100%":"65%", margin:!isMobile?"3rem auto":0, justifyContent:"center"}}  >
          <Grid item xs={12} p={1}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <AccountCircleIcon sx={(theme) => ({ color: theme.palette.primary.main , paddingRight: "1rem" })}/>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {getLabel("agreements.title", "Agreements")}
              </Typography>
            </div>
          </Grid>
          <Paper
            elevation={2}
            sx={{
              height: "22rem",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignContent: "center",
              cursor: "pointer",
              textAlign: "center",
              minWidth: "90%",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.25)",
            }}
          >
         {documents.map((doc) => (
          <>
            <Grid container key={doc.key}  justifyContent="center" paddingY="0.5rem">
              <Grid item xs={8} container alignItems="center">
                <Typography key={doc.key + "t1"} variant="body1">
                  {doc.name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                justifyContent="center"
                alignItems="center"
              >
                  { (!keyClicked.includes(doc.key) || (!downloadHtml.isLoading && !donwloadPDF.isLoading)) ? (
                  <Button
                    sx= {{display: (doc.type === "HTML")  || (doc.type === "PDF" && !doc.url)  ? "block" : "none"  }}
                    onClick={(event) =>
                    handleDownloadAgreementClick(event, doc.key, doc.type)
                    }
                  >
                    {doc.type === "PDF"?  !doc.url && <DownloadingOutlinedIcon />
                     : (
                      <VisibilityIcon />
                    )}
                  </Button>
                 ) 
                : <CircularProgress size={15}/>
              }
              { (doc.type === "PDF" && doc.url) &&
                <Button
                  ref={el => seePDFsRefs.current[doc.key] = el}
                  href={doc.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VisibilityIcon />
                </Button>
              }
              </Grid>
            </Grid>
            <Divider style={{marginInline:"1rem"}} orientation="horizontal" flexItem />
          </>
          ))}

          </Paper> 


          <Grid item xs={12} pt={0}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            </div>
            <Grid alignContent="center" item>
              <LoadingButton
                sx={{
                  marginTop: "2rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  navigate(-1);;
                }}
              >
                {getLabel("general.back", "Go Back")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
