/* Terms and Conditions */
import * as React from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import useLabels from "../../labels/useLabels";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HttpService from "../../services/HttpService";
import { useMutation } from "@tanstack/react-query";
import OnboardingEmployerAttributeService from "../../services/config/OnboardingEmployerAttributeService";
import store from "../../store/store";

export default function StepTermsAndConditions({ onSave, email, token, bagContent, currentStep, onCheck, employerId, completeName }) {
  const thisStep = "6";
  const onboardingEmployerAttributes = OnboardingEmployerAttributeService(employerId, "DIRECT_DEPOSIT_ACCEPT,HIDE_FEE_SCHEDULE_ACCEPT,HIDE_CARDHOLDER_ACCEPT,HIDE_PRODUCT_ACCEPT").attribute;
  const [documents, setDocuments] = React.useState([]);

  const seePDFsRefs = React.useRef({});
  const language = store.getState()?.language?.loginLanguage;
  const cardType = bagContent?.cardType || "";



  React.useEffect (() => {
    const documents = [
      ...(onboardingEmployerAttributes?.HIDE_FEE_SCHEDULE_ACCEPT ?  []: [{ name: "Fee Schedule", key: "feeSchedule_" + language, type: "HTML", url : null, checked: null },]),
      { name: "USA Patriot Act", key: "patriotAct_" + language, type: "HTML", url : null, checked: null },
      { name: "ESING Disclousure", key: "eSignDisclosure_" + language, type: "PDF" , url : null, checked: null},
      ...(onboardingEmployerAttributes?.HIDE_CARDHOLDER_ACCEPT ? []: [{name: "Cardholder agreement",key: "cardHolderAgreement_" + language,type: "PDF",url : null, checked: null},]),
      ...(onboardingEmployerAttributes?.HIDE_PRODUCT_ACCEPT ?  [] :[{name: "Digital product agreement",key: "digitalAccountAgreement_" + language,type: "PDF",url : null, checked: null},]),
      ...(onboardingEmployerAttributes?.DIRECT_DEPOSIT_ACCEPT ?   [{name: "Change my Direct Deposit",key: "changeMyDirectDeposit_" + language,type: "PDF",url : null, checked: null},]: []),
    ];
    setDocuments(documents)
  }, [
      onboardingEmployerAttributes?.DIRECT_DEPOSIT_ACCEPT, 
      onboardingEmployerAttributes?.HIDE_PRODUCT_ACCEPT,
      onboardingEmployerAttributes?.HIDE_CARDHOLDER_ACCEPT,
      onboardingEmployerAttributes?.HIDE_FEE_SCHEDULE_ACCEPT,
      completeName, 
      language]);

  const setURL = (key, url) => {
    const updatedDocuments = documents.map((doc) => {
      if (doc.key === key) {
        return { ...doc, url: url };
      }
      return doc;
    });
    setDocuments(updatedDocuments);
  };

  
  const { getLabel } = useLabels();
  const [htmlContent, setHtmlContent] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [allChecked, setAllChecked] = React.useState(false);
  const [keyClicked, setKeyClicked] = React.useState([]);
  
  React.useEffect(() => {
    if (email === "" || email === undefined) return;
    setDocuments(
      documents.map((doc) => { 
        const checked = bagContent[doc.key] === "true";
        return ({ ...doc, checked: checked })})
    );  
  }, [bagContent]);

  const handleCheckboxChange = (event, documentKey) => {
    const updatedCheckedDocuments = documents.map((doc) => {
      if (doc.key === documentKey) {
        return { ...doc, checked: event.target.checked };
      }
      return doc;
    });
    setDocuments(updatedCheckedDocuments);
  };
  const handleDownloadClick = (event, key, type) => {
    setKeyClicked(prevKeys => [...prevKeys, key]);

    if (type === "PDF") 
      donwloadPDF.mutate(key);
    if (type === "HTML") 
      downloadHtml.mutate(key);
    event.preventDefault();
  };
  const handleDialogClose = (event, reason) => {
    if (reason !== "backdropClick") setOpenDialog(false);
  };

  const dialogUI = (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Grid>
          <Grid item width="100%">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleDialogClose}
            >
              {getLabel("cardActivation.btnClose", "Close")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
  React.useEffect(() => {
    if (email === "" || email === undefined) return;
    if (Number(thisStep) > Number(currentStep)) return;

    const allChecked = documents.every((doc) => doc.checked);
    setAllChecked(allChecked);
    
    const checkedDocumentsMap = Object.assign({}, ...documents.filter(doc => doc.checked !== null).map(doc => ({ [doc.key]: doc.checked.toString() })));
    if (Object.keys(checkedDocumentsMap).length === 0) return;

    processStep.mutate({
      email: email,
      token: token,
      step: allChecked ? (Number(thisStep)+1).toString() : thisStep.toString(),
      bagContent: {
        ...checkedDocumentsMap,
      },
    });
  }, [documents]);

  const processStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/saveStep`,
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {onCheck(allChecked ? (Number(thisStep)+1).toString() : thisStep.toString())},
      onError: (values) => {},
    }
  );

  const donwloadPDF = useMutation(
    (key) => {
      return HttpService.getAxiosClient().get(
        window.API_URL + `/open/document/` + key + `/getPDF?cardType=${cardType}&employerId=${employerId}&name=${completeName}`,
        {responseType: "json",},
        { avoidBearer: true }
      ).then(response => ({response, key}));
    },
    {
      onSuccess: ({response, key}) => {
        const binaryString = window.atob(response.data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        setURL(key, url);
        setKeyClicked(prevKeys => prevKeys.filter(currentKey => currentKey !== key));
      },
      onError: ({response, key}) => {
        setKeyClicked(prevKeys => prevKeys.filter(currentKey => currentKey !== key));
      },
    }
  );

  const downloadHtml = useMutation(
    (key) => {
      return HttpService.getAxiosClient().get(
        window.API_URL + `/open/document/` + key + `/getHTML?cardType=${cardType}`,
        {},
        { avoidBearer: true }
      ).then(response => ({response, key}));;
    },
    {
      onSuccess: ({response, key}) => { 
        setHtmlContent(response.data);
        setOpenDialog(true);
        setKeyClicked(prevKeys => prevKeys.filter(currentKey => currentKey !== key));
      },
      onError: ({response, key}) => {
        setKeyClicked(prevKeys => prevKeys.filter(currentKey => currentKey !== key));
      },
    }
  );


  return (
    <>
      {dialogUI}
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body1">
            <b>{getLabel("cardActivation.table.lblDocument", "Document")}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container justifyContent="center">
          <Typography variant="body1">
            <b>{getLabel("cardActivation.table.lblReview", "Review")}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container justifyContent="center">
          <Typography variant="body1">
            <b>{getLabel("cardActivation.table.lblAccept", "Accept")}</b>
          </Typography>
        </Grid>
        {documents.length>0 ? documents.map((doc) => (
          <Grid container key={doc.key}>
            <Grid item xs={8} container alignItems="center">
              <Typography key={doc.key + "t1"} variant="body1">
                {doc.name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              justifyContent="center"
              alignItems="center"
            >
              { (!keyClicked.includes(doc.key) || (!downloadHtml.isLoading && !donwloadPDF.isLoading)) ? (
                  <Button
                    sx= {{display: (doc.type === "HTML")  || (doc.type === "PDF" && !doc.url)  ? "block" : "none"  }}
                    onClick={(event) =>
                    handleDownloadClick(event, doc.key, doc.type)
                    }
                  >
                    {doc.type === "PDF"?  !doc.url && <DownloadingOutlinedIcon />
                     : (
                      <VisibilityIcon />
                    )}
                  </Button>
                 ) 
                : <CircularProgress size={15}/>
              }
              { (doc.type === "PDF" && doc.url) &&
                <Button
                  ref={el => seePDFsRefs.current[doc.key] = el}
                  href={doc.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VisibilityIcon />
                </Button>
              }
            </Grid>
            <Grid
              item
              xs={2}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Checkbox
                key={doc.key + ".chk"}
                checked={!!doc?.checked}
                onChange={(event) => handleCheckboxChange(event, doc.key)}
              />
            </Grid>
          </Grid>
        )) : <></>}
        {allChecked ? (
          <Grid item xs={12}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={onSave}
            >
              {getLabel("onboarding.step6.btnContinue", "Continue")}
            </Button>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}
